var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center mb-4",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment.will-order")) + " "), _c('i', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mdi mdi-information",
    attrs: {
      "title": _vm.$t('info-message-tooltip')
    }
  })]), _c('div', {
    staticClass: "form-group",
    staticStyle: {
      "text-align": "right"
    }
  }, [[0, 1].includes(_vm.amendment_status) && _vm.contentExist == true ? _c('b-button', {
    staticClass: "btn btn-sm btn-primary mr-1",
    on: {
      "click": _vm.copyContent
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("copy-prev-amendment-msg")) + " ")]) : _vm._e(), [0, 1].includes(_vm.amendment_status) && _vm.contentExist == true ? _c('b-button', {
    staticClass: "btn btn-sm btn-danger",
    on: {
      "click": _vm.clearContent
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")]) : _vm._e()], 1), _c('form', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('quill-editor', {
    ref: "editor",
    attrs: {
      "options": _vm.editorOption
    },
    on: {
      "blur": function ($event) {
        return _vm.onEditorBlur($event);
      },
      "focus": function ($event) {
        return _vm.onEditorFocus($event);
      },
      "ready": function ($event) {
        return _vm.onEditorReady($event);
      },
      "change": function ($event) {
        return _vm.onEditorChange($event);
      }
    },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1)])])])])]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [[0, 1].includes(_vm.amendment_status) ? _c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.simpanMessage
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-next")) + " ")]) : _vm._e()], 1)])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }