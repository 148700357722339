import { render, staticRenderFns } from "./pindaan-f.vue?vue&type=template&id=774f1a4c&scoped=true&"
import script from "./pindaan-f.vue?vue&type=script&lang=js&"
export * from "./pindaan-f.vue?vue&type=script&lang=js&"
import style0 from "./pindaan-f.vue?vue&type=style&index=0&id=774f1a4c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "774f1a4c",
  null
  
)

export default component.exports